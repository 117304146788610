// Generated by Framer (d2515d1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./eEVfUF8Gk-0.js";

const cycleOrder = ["oQaIse9Zm", "Lj6_MmYZL"];

const serializationHash = "framer-d86HB"

const variantClassNames = {Lj6_MmYZL: "framer-v-vkyp2d", oQaIse9Zm: "framer-v-i2531k"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {Mwo2KOPC6: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 76, delay: 0, mass: 1, stiffness: 294, type: "spring"}

const transition2 = {delay: 0, duration: 0.8, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {InView: "Lj6_MmYZL", NotInView: "oQaIse9Zm"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "oQaIse9Zm", vdf5Q5XtF: title ?? props.vdf5Q5XtF ?? "Name", WojX2Di1q: link ?? props.WojX2Di1q} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, vdf5Q5XtF, WojX2Di1q, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "oQaIse9Zm", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({Lj6_MmYZL: {value: transition2}}, baseVariant, gestureVariant)}><Link href={WojX2Di1q} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-i2531k", className, classNames)} framer-lqgqz7`} data-framer-name={"NotInView"} layoutDependency={layoutDependency} layoutId={"oQaIse9Zm"} ref={ref ?? ref1} style={{opacity: 0.3, ...style}} variants={{Lj6_MmYZL: {opacity: 1}}} {...addPropertyOverrides({Lj6_MmYZL: {"data-framer-name": "InView"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0V1Y2xpZCBDaXJjdWxhciBBIE1lZGl1bQ==", "--framer-font-family": "\"Euclid Circular A Medium\", \"Euclid Circular A Medium Placeholder\", sans-serif", "--framer-line-height": "120%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(51, 51, 51))"}}>Name</motion.p></React.Fragment>} className={"framer-1gqdhl8"} fonts={["CUSTOM;Euclid Circular A Medium"]} layoutDependency={layoutDependency} layoutId={"ytDm7Ibl_"} style={{"--extracted-r6o4lv": "rgb(51, 51, 51)"}} text={vdf5Q5XtF} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-d86HB.framer-lqgqz7, .framer-d86HB .framer-lqgqz7 { display: block; }", ".framer-d86HB.framer-i2531k { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 46px; }", ".framer-d86HB .framer-1gqdhl8 { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-d86HB.framer-i2531k { gap: 0px; } .framer-d86HB.framer-i2531k > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-d86HB.framer-i2531k > :first-child { margin-left: 0px; } .framer-d86HB.framer-i2531k > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38.5
 * @framerIntrinsicWidth 46
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"Lj6_MmYZL":{"layout":["fixed","auto"]}}}
 * @framerVariables {"vdf5Q5XtF":"title","WojX2Di1q":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramereEVfUF8Gk: React.ComponentType<Props> = withCSS(Component, css, "framer-d86HB") as typeof Component;
export default FramereEVfUF8Gk;

FramereEVfUF8Gk.displayName = "Change by Design - Section menu";

FramereEVfUF8Gk.defaultProps = {height: 38.5, width: 46};

addPropertyControls(FramereEVfUF8Gk, {variant: {options: ["oQaIse9Zm", "Lj6_MmYZL"], optionTitles: ["NotInView", "InView"], title: "Variant", type: ControlType.Enum}, vdf5Q5XtF: {defaultValue: "Name", displayTextArea: false, title: "Title", type: ControlType.String}, WojX2Di1q: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramereEVfUF8Gk, [{explicitInter: true, fonts: [{family: "Euclid Circular A Medium", source: "custom", url: "https://framerusercontent.com/assets/N2tp5hcX9M9DE4TiUdEuanrsE.woff2"}]}], {supportsExplicitInterCodegen: true})